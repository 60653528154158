import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export default ({ breakpoints, spacing }: Theme) =>
  createStyles({
    root: {
      width: 400,
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(6),
      padding: spacing(6),
      fontSize: 14,
      fontWeight: 400,
      color: colors.black.darkest,
    },

    [breakpoints.down('xs')]: {
      root: {
        minWidth: 'auto',
      },
    },
  });
