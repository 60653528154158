import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';
import { createStyles } from '@core/theme/utils/create-styles';
import { fieldBorderRadius } from '@shared/constants/field';

export const styles = ({ spacing, shape }: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      alignSelf: 'flex-end',
      marginBottom: spacing(2),
    },
    label: {
      fontSize: 14,
      color: colors.black.darkest,
      marginBottom: spacing(0.5),
    },
    rowWrap: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
    },
    rowWrapReverse: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',

      '& $fieldInput': {
        borderTopRightRadius: fieldBorderRadius,
        borderBottomRightRadius: fieldBorderRadius,

        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '& $cardLabel': {
        borderBottomLeftRadius: fieldBorderRadius,
        borderBottomRightRadius: 0,
        borderLeftWidth: 1,
        borderRightWidth: 0,
        borderTopLeftRadius: fieldBorderRadius,
        borderTopRightRadius: 0,
      },

      '& $cardSubLabel': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: fieldBorderRadius,
        borderLeftWidth: 0,
        borderRightWidth: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: fieldBorderRadius,
      },
    },
    disabled: {
      color: colors.grey.main,
    },
    cardLabel: {
      background: colors.grey.lightest,
      border: `1px solid ${alpha(colors.grey.main, 0.5)}`,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: fieldBorderRadius,
      borderLeftWidth: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: fieldBorderRadius,
      color: colors.grey.main,
      fontSize: 14,
      height: 36,
      lineHeight: '34px',
      padding: '2px 8px 0',
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 'auto',
    },
    cardSubLabel: {},
    // Parent classes
    field: {},
    fieldInput: {},
    noBorderRadius: {},
  });
