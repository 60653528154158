import { CSSProperties } from '@material-ui/styles';

import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

// TODO: Change all hexcodes when palette is updated

type ButtonStyling = Record<
  ButtonColour,
  {
    default: CSSProperties;
    hovered: CSSProperties;
    active: CSSProperties;
    disabled: CSSProperties;
  }
>;

const buttonDisabledStyling: CSSProperties = {
  backgroundColor: colors.kato.grey[200],
  borderColor: colors.kato.grey[200],
  boxShadow: 'none',
  color: colors.kato.grey[400],
  cursor: 'not-allowed',
};

export const coreButtonStyling: ButtonStyling = {
  // Primary
  [ButtonColour.primary]: {
    default: {
      backgroundColor: colors.kato.navy[950],
      borderColor: colors.kato.navy[950],
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: colors.kato.navy[950],
      borderColor: colors.kato.navy[950],
    },
    active: {
      backgroundColor: colors.kato.navy[950],
      borderColor: colors.kato.navy[950],
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Primary Special
  [ButtonColour.primarySpecial]: {
    default: {
      backgroundColor: colors.kato.salmon[500],
      borderColor: colors.kato.salmon[500],
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: colors.kato.salmon[600],
      borderColor: colors.kato.salmon[600],
    },
    active: {
      backgroundColor: colors.kato.salmon[600],
      borderColor: colors.kato.salmon[600],
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Secondary
  [ButtonColour.secondary]: {
    default: {
      backgroundColor: colors.white.lightest,
      borderColor: '#D1D6DF',
      color: '#344154',
    },
    hovered: {
      backgroundColor: '#F7F8FA',
      borderColor: '#D1D6DF',
    },
    active: {
      backgroundColor: '#EAECF0',
      borderColor: '#D1D6DF',
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Secondary Colour
  [ButtonColour.secondaryColour]: {
    default: {
      backgroundColor: colors.white.lightest,
      borderColor: colors.kato.grey[300],
      color: colors.kato.navy[950],
      boxShadow: 'none',
    },
    hovered: {
      backgroundColor: colors.kato.grey[100],
      borderColor: colors.kato.navy[950],
      boxShadow: 'none',
      color: colors.kato.navy[950],
    },
    active: {
      backgroundColor: colors.kato.grey[100],
      borderColor: colors.kato.navy[950],
      boxShadow: 'none',
      color: colors.kato.navy[950],
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Secondary Ghost
  [ButtonColour.secondaryGhost]: {
    default: {
      backgroundColor: colors.white.lightest,
      border: 'none',
      boxShadow: 'none',
      color: '#344154',
    },
    hovered: {
      backgroundColor: '#F2F4F7',
    },
    active: {
      backgroundColor: '#EAECF0',
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Error
  [ButtonColour.error]: {
    default: {
      backgroundColor: '#D92D20',
      borderColor: '#D92D20',
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: '#B42318',
      borderColor: '#B42318',
    },
    active: {
      backgroundColor: '#912018',
      borderColor: '#912018',
    },
    disabled: { ...buttonDisabledStyling },
  },
};

const currentColorStyling = {
  '& $buttonLabel i': { color: 'currentColor' },
  '& $buttonLabel svg': { color: 'currentColor' },
  '& $buttonLabel svg path': { stroke: 'currentColor' },
};

const buttonStyling: ButtonStyling = {
  [ButtonColour.primary]: {
    ...coreButtonStyling[ButtonColour.primary],

    default: { ...coreButtonStyling[ButtonColour.primary].default, ...currentColorStyling },
    hovered: { ...coreButtonStyling[ButtonColour.primary].hovered, ...currentColorStyling },
    active: { ...coreButtonStyling[ButtonColour.primary].active, ...currentColorStyling },
    disabled: { ...coreButtonStyling[ButtonColour.primary].disabled, ...currentColorStyling },
  },
  [ButtonColour.primarySpecial]: {
    ...coreButtonStyling[ButtonColour.primarySpecial],

    default: { ...coreButtonStyling[ButtonColour.primarySpecial].default, ...currentColorStyling },
    hovered: { ...coreButtonStyling[ButtonColour.primarySpecial].hovered, ...currentColorStyling },
    active: { ...coreButtonStyling[ButtonColour.primarySpecial].active, ...currentColorStyling },
    disabled: { ...coreButtonStyling[ButtonColour.primarySpecial].disabled, ...currentColorStyling },
  },
  // Secondary
  [ButtonColour.secondary]: {
    ...coreButtonStyling[ButtonColour.secondary],

    default: {
      ...coreButtonStyling[ButtonColour.secondary].default,
      '& $buttonLabel i': { color: '#7E8899' },
      '& $buttonLabel svg': { color: '#7E8899' },
      '& $buttonLabel svg path': { stroke: '#7E8899' },
    },
    hovered: { ...coreButtonStyling[ButtonColour.secondary].hovered, ...currentColorStyling },
    active: { ...coreButtonStyling[ButtonColour.secondary].active, ...currentColorStyling },
    disabled: { ...coreButtonStyling[ButtonColour.secondary].disabled, ...currentColorStyling },
  },
  // Secondary Colour
  [ButtonColour.secondaryColour]: {
    ...coreButtonStyling[ButtonColour.secondaryColour],

    default: {
      ...coreButtonStyling[ButtonColour.secondaryColour].default,
      '& $buttonLabel i': { color: '#7E8899' },
      '& $buttonLabel svg': { color: '#7E8899' },
      '& $buttonLabel svg path': { stroke: '#7E8899' },
    },
    hovered: { ...coreButtonStyling[ButtonColour.secondaryColour].hovered, ...currentColorStyling },
    active: { ...coreButtonStyling[ButtonColour.secondaryColour].active, ...currentColorStyling },
    disabled: { ...coreButtonStyling[ButtonColour.secondaryColour].disabled, ...currentColorStyling },
  },
  // Secondary Ghost
  [ButtonColour.secondaryGhost]: {
    ...coreButtonStyling[ButtonColour.secondaryGhost],

    default: {
      ...coreButtonStyling[ButtonColour.secondaryGhost].default,
      '& $buttonLabel i': { color: '#7E8899' },
      '& $buttonLabel svg': { color: '#7E8899' },
      '& $buttonLabel svg path': { stroke: '#7E8899' },
    },
    hovered: { ...coreButtonStyling[ButtonColour.secondaryGhost].hovered, ...currentColorStyling },
    active: { ...coreButtonStyling[ButtonColour.secondaryGhost].active, ...currentColorStyling },
    disabled: { ...coreButtonStyling[ButtonColour.secondaryGhost].disabled, ...currentColorStyling },
  },
  // Error
  [ButtonColour.error]: {
    ...coreButtonStyling[ButtonColour.error],

    default: { ...coreButtonStyling[ButtonColour.error].default, ...currentColorStyling },
    hovered: { ...coreButtonStyling[ButtonColour.error].hovered, ...currentColorStyling },
    active: { ...coreButtonStyling[ButtonColour.error].active, ...currentColorStyling },
    disabled: { ...coreButtonStyling[ButtonColour.error].disabled, ...currentColorStyling },
  },
};

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {
      border: '1px solid transparent',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
      minWidth: 10,
      ...currentColorStyling,
    },
    buttonActive: {},
    buttonDisabled: {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    buttonHovered: {},

    buttonStartIcon: {
      marginLeft: 0,
      marginRight: spacing(2),
    },
    buttonLabel: {
      fontWeight: 500,
    },
    buttonEndIcon: {
      marginLeft: spacing(2),
      marginRight: 0,
    },

    // Colours
    [ButtonColour.primary]: {
      ...buttonStyling[ButtonColour.primary].default,

      '&:hover': { ...buttonStyling[ButtonColour.primary].hovered },
      '&$buttonHovered': { ...buttonStyling[ButtonColour.primary].hovered },
      '&:active': { ...buttonStyling[ButtonColour.primary].active },
      '&$buttonActive': { ...buttonStyling[ButtonColour.primary].active },
      '&$buttonDisabled': { ...buttonStyling[ButtonColour.primary].disabled },
    },
    [ButtonColour.primarySpecial]: {
      ...buttonStyling[ButtonColour.primarySpecial].default,

      '&:hover': { ...buttonStyling[ButtonColour.primarySpecial].hovered },
      '&$buttonHovered': { ...buttonStyling[ButtonColour.primarySpecial].hovered },
      '&:active': { ...buttonStyling[ButtonColour.primarySpecial].active },
      '&$buttonActive': { ...buttonStyling[ButtonColour.primarySpecial].active },
      '&$buttonDisabled': { ...buttonStyling[ButtonColour.primarySpecial].disabled },
    },
    [ButtonColour.secondary]: {
      ...buttonStyling[ButtonColour.secondary].default,

      '&:hover': { ...buttonStyling[ButtonColour.secondary].hovered },
      '&$buttonHovered': { ...buttonStyling[ButtonColour.secondary].hovered },
      '&:active': { ...buttonStyling[ButtonColour.secondary].active },
      '&$buttonActive': { ...buttonStyling[ButtonColour.secondary].active },
      '&$buttonDisabled': { ...buttonStyling[ButtonColour.secondary].disabled },
    },
    [ButtonColour.secondaryColour]: {
      ...buttonStyling[ButtonColour.secondaryColour].default,

      '&:hover': { ...buttonStyling[ButtonColour.secondaryColour].hovered },
      '&$buttonHovered': { ...buttonStyling[ButtonColour.secondaryColour].hovered },
      '&:active': { ...buttonStyling[ButtonColour.secondaryColour].active },
      '&$buttonActive': { ...buttonStyling[ButtonColour.secondaryColour].active },
      '&$buttonDisabled': { ...buttonStyling[ButtonColour.secondaryColour].disabled },
    },
    [ButtonColour.secondaryGhost]: {
      ...buttonStyling[ButtonColour.secondaryGhost].default,

      '&:hover': { ...buttonStyling[ButtonColour.secondaryGhost].hovered },
      '&$buttonHovered': { ...buttonStyling[ButtonColour.secondaryGhost].hovered },
      '&:active': { ...buttonStyling[ButtonColour.secondaryGhost].active },
      '&$buttonActive': { ...buttonStyling[ButtonColour.secondaryGhost].active },
      '&$buttonDisabled': { ...buttonStyling[ButtonColour.secondaryGhost].disabled },
    },
    [ButtonColour.error]: {
      ...buttonStyling[ButtonColour.error].default,

      '&:hover': { ...buttonStyling[ButtonColour.error].hovered },
      '&$buttonHovered': { ...buttonStyling[ButtonColour.error].hovered },
      '&:active': { ...buttonStyling[ButtonColour.error].active },
      '&$buttonActive': { ...buttonStyling[ButtonColour.error].active },
      '&$buttonDisabled': { ...buttonStyling[ButtonColour.error].disabled },
    },

    // Sizes
    [ButtonSize.extraSmall]: {
      height: 24,
      padding: spacing(0, 1.5),

      '& $buttonLabel': {
        fontSize: 12,
      },
    },
    [ButtonSize.small]: {
      height: 32,
      padding: spacing(0, 3),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.mediumLegacy]: {
      height: 36,
      padding: spacing(0, 3),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.medium]: {
      height: 40,
      padding: spacing(0, 4),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.large]: {
      height: 48,
      padding: spacing(0, 4),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    // Icon sizes
    [ButtonSize.iconSmall]: {
      height: 32,
      padding: spacing(3),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    [ButtonSize.iconLarge]: {
      height: 40,
      padding: spacing(2),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
  });
};
