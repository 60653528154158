import cx from 'classnames';
import { ReactNode, useEffect} from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';

import { styles } from './FieldWithCard.styles';

export interface FieldWithCardProps extends WithStyles<typeof styles> {
  cardLabel: string | ReactNode;
  cardSubLabel?: string | ReactNode;
  cardLabelPosition?: 'left' | 'right';
  field: ReactNode;
  label?: string;
  setFieldInputClass: (className: string) => void;
}

const FieldWithCardComponent: React.FC<FieldWithCardProps> = ({
  cardLabel,
  cardSubLabel,
  cardLabelPosition = 'right',
  classes,
  field,
  label,
  setFieldInputClass,
}) => {
  useEffect(() => setFieldInputClass(classes.fieldInput), [classes.fieldInput]);

  return (
    <Flex autoWidth={false} direction="column" classes={{ root: classes.root }}>
      {label && <span className={classes.label}>{label}</span>}
      <Flex
        wrap="nowrap"
        classes={{ root: cx(classes.rowWrap, { [classes.rowWrapReverse]: cardLabelPosition === 'left' }) }}
      >
        { cardSubLabel && <div className={classes.cardSubLabel}>{cardSubLabel}</div> }
        {field}
        <div className={classes.cardLabel}>{cardLabel}</div>
      </Flex>
    </Flex>
  );
};

export const FieldWithCard = withStyles(styles)(FieldWithCardComponent);
