import { createStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return createStyles({
    root: {},
    select: {},
    label: {},
    errorText: {},
  });
}
